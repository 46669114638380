/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from 'react';
import dynamic from 'next/dynamic';
import classNames from 'classnames/bind';

import { COLOR_TYPES } from '@lib/constants/colors.constants';

import { useAppDispatch } from '@hooks/redux/useAppDispatch';
import { useAppSelector } from '@hooks/redux/useAppSelector';
import { useSearchSuggestion } from '@hooks/search/useSearchSuggestion';
import useOutsideClick from '@hooks/useOutsideClick';

import { selectIsSearchDetailedOpen } from '@store/searchForm/selector';
import {
  setSearchDetailedOpen,
  setSearchDetailedVisible,
} from '@store/searchForm/slice';

import ButtonAsLink from '@components/controls/Button/ButtonAsLink';
import ButtonSearch from '@components/controls/Button/ButtonSearch';
import ArrowDownIconSmall from '@components/icons/functions/ArrowDownIconSmall';
import ArrowUpIconSmall from '@components/icons/functions/ArrowUpIconSmall';

import Input, { InputProps } from './Input';

import style from './InputSearch.module.scss';

const setClass = classNames.bind(style);

const SuggestionList = dynamic(
  () => import('@components/search/desktop/SuggestionList/SuggestionList'),
  { ssr: true }
);

type InputSearchProps = {
  handleSubmitSearch: () => void;
  selectedFilters: number;
};

const InputSearch = ({
  className,
  ...props
}: InputProps & InputSearchProps) => {
  const {
    field: { value },
    handleSubmitSearch,
    selectedFilters = 0,
  } = props;

  const inputEl = useRef(null);
  const dispatch = useAppDispatch();
  const suggestionRef = useRef(null);
  const isOpen = useAppSelector(selectIsSearchDetailedOpen);

  const {
    getSearchRecent,
    handleClick,
    onChange,
    onKeyDown,
    activeSuggestion,
    setActiveSuggestion,
    setSuggestions,
    suggestions,
    recentSearch,
    showSuggestion,
    setShowSuggestion,
    isLoading,
  } = useSearchSuggestion({
    value,
    handleSubmitSearch,
  });

  const openDetailedSearch = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(setSearchDetailedOpen(true));
    dispatch(setSearchDetailedVisible(true));
  };

  const closeDetailedSearch = (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(setSearchDetailedOpen(false));
  };

  useOutsideClick([suggestionRef, inputEl], () => {
    if (showSuggestion) {
      setShowSuggestion(false);
      setActiveSuggestion(null);
      setSuggestions(recentSearch);
    }
  });

  return (
    <section className={style.searchHolder}>
      <Input
        className={setClass(
          {
            search: true,
            'data-hj-allow': true,
          },
          className
        )}
        trailing={<ButtonSearch onClick={handleSubmitSearch} />}
        trailingInner={
          <ButtonAsLink
            primary
            className={style.buttonInner}
            onClick={(e) =>
              isOpen ? closeDetailedSearch(e) : openDetailedSearch(e)
            }
            trailing={
              isOpen ? (
                <ArrowUpIconSmall
                  stroke={COLOR_TYPES.contentInteractivePrimary}
                />
              ) : (
                <ArrowDownIconSmall
                  stroke={COLOR_TYPES.contentInteractivePrimary}
                />
              )
            }
          >
            {`Pretražite detaljno ${
              selectedFilters ? `(${selectedFilters})` : ''
            }`}
          </ButtonAsLink>
        }
        onChange={onChange}
        onKeyDown={onKeyDown}
        onClick={() => {
          getSearchRecent();
          setShowSuggestion(true);
        }}
        autoFocus={!isOpen}
        onDrop={() => {}}
        {...props}
      />
      {showSuggestion && (
        <SuggestionList
          suggestionRef={suggestionRef}
          suggestions={suggestions}
          activeSuggestion={activeSuggestion}
          setActiveSuggestion={setActiveSuggestion}
          onClick={handleClick}
          isLoading={isLoading}
        />
      )}
    </section>
  );
};

export default InputSearch;
