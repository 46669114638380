import React from 'react';

import { IconProps } from '@type/icons';

import asIcon from '../asIcon';

const ArrowUpIconSmall = ({ className, stroke, fill }: IconProps) => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill={fill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1 6L3.85867 3.06027C3.89613 3.02168 3.94698 3 4 3C4.05302 3 4.10387 3.02168 4.14133 3.06027L7 6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default asIcon(ArrowUpIconSmall);
