import React, { ReactNode, useRef } from 'react';
import { FieldProps } from 'formik';
import classNames from 'classnames/bind';

import style from './Checkbox.module.scss';

const setClass = classNames.bind(style);

type CheckboxProps = {
  id: string;
  label?: string;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  className?: string;
  isDisabled?: boolean;
  displayError?: boolean;
  labelIcon?: ReactNode;
  hideEmptyError?: boolean;
};

const Checkbox = ({
  field: { name, value, onChange, onBlur },
  id,
  label = '',
  form: { errors, touched },
  onClick,
  className = '',
  isDisabled = false,
  displayError = false,
  labelIcon = '',
  hideEmptyError = false,
}: CheckboxProps & FieldProps) => {
  const inputEl = useRef(null);
  const isTouched = touched[name];
  const isMultiError = errors[`${name}${id}`] && isTouched;
  const isSingleError = errors[name] && isTouched;

  const checkboxClass = setClass({
    checkmark: true,
    error: isSingleError || isMultiError,
  });

  return (
    <>
      <span
        className={setClass({ holder: true }, className)}
        onMouseDown={(event) => {
          event.preventDefault();
          inputEl.current.focus();
        }}
        role="checkbox"
        aria-checked={value?.includes(id)}
        tabIndex={-1}
      >
        <label htmlFor={`${name}${id}`} className={style.container}>
          <input
            aria-label={name}
            ref={inputEl}
            id={`${name}${id}`}
            type="checkbox"
            value={id}
            name={name}
            onChange={onChange}
            onClick={onClick}
            onBlur={onBlur}
            checked={value.includes(id)}
            disabled={isDisabled}
            error={(isMultiError && `${name}${id}`) || (isSingleError && name)}
            data-test={`${name}-${id}-input`}
          />
          <div
            className={checkboxClass}
            data-test={`${name}-${id}-checkmark`}
          />
          {labelIcon && <span className={style.labelIcon}>{labelIcon}</span>}
          {label && <span className={style.label}>{label}</span>}
        </label>
      </span>
      {displayError && (
        <span className={setClass({ displayError: true, hideEmptyError })}>
          {(isMultiError && String(errors[`${name}${id}`])) ||
            (isSingleError && String(errors[name]))}
        </span>
      )}
    </>
  );
};

export default Checkbox;
