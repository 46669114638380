import React, { memo, useEffect, useRef } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import isEmpty from 'lodash/isEmpty';
import { Field, FormikValues } from 'formik';
import classNames from 'classnames/bind';
import isEqual from 'react-fast-compare';

import { useAppDispatch } from '@hooks/redux/useAppDispatch';
import { useAppSelector } from '@hooks/redux/useAppSelector';
import { useSearch } from '@hooks/search/useSearch';
import useOutsideClick from '@hooks/useOutsideClick';

import { selectPageType } from '@store/meta/selector';
import {
  selectIsSearchDetailedOpen,
  selectIsSearchDetailedVisible,
} from '@store/searchForm/selector';
import { setSearchDetailedVisible } from '@store/searchForm/slice';

import Checkbox from '@components/controls/Checkbox/Checkbox';
import InputSearch from '@components/controls/Input/InputSearch';

import style from './Search.module.scss';

const setClass = classNames.bind(style);

const SearchDetailed = dynamic(() => import('./SearchDetailed'), { ssr: true });

const SearchForm = ({ values, setFieldValue }: FormikValues) => {
  const dispatch = useAppDispatch();
  const { query } = useRouter();
  const ref = useRef();
  const searchHolderRef = useRef();
  const { searchPlaceholder, handleSubmitSearch, selectedFilters, close } =
    useSearch({
      values,
      setFieldValue,
    });

  const isOpen = useAppSelector(selectIsSearchDetailedOpen);
  const isSearchDetailedVisible = useAppSelector(selectIsSearchDetailedVisible);
  const pageType = useAppSelector(selectPageType);

  useEffect(() => {
    if (isEmpty(query.keywords)) {
      setFieldValue('keywords', '');
    }
  }, [setFieldValue, query]);

  const isAllUserAdsPage = pageType === 'allUserAds';

  const searchHolder = setClass({
    searchHolder: true,
    centerHeight: true,
    isOpen,
  });
  const searchClass = setClass({
    search: true,
    isOpen,
  });
  const searchHolderFilters = setClass({
    searchFilters: true,
    isOpen,
  });

  useOutsideClick(
    searchHolderRef,
    () =>
      isOpen &&
      setTimeout(() => {
        close();
      }, 300)
  );

  return (
    <>
      <form>
        <section ref={searchHolderRef}>
          <div
            className={searchClass}
            ref={ref}
            onTransitionEnd={() => {
              if (!isOpen && isSearchDetailedVisible)
                dispatch(setSearchDetailedVisible(false));
            }}
          >
            <div className={searchHolder}>
              <div className={style.searchInputHolder}>
                <Field
                  name="keywords"
                  placeholder={searchPlaceholder}
                  component={InputSearch}
                  handleSubmitSearch={handleSubmitSearch}
                  selectedFilters={selectedFilters}
                />
              </div>
              {isAllUserAdsPage && (
                <div className={style.onKpCheckbox}>
                  <Field
                    id="yes"
                    name="ignoreUserId"
                    component={Checkbox}
                    label="Pretraži ceo kp"
                  />
                </div>
              )}
            </div>

            <div
              className={style.animated}
              data-state={isOpen ? 'show' : 'hide'}
            >
              {isSearchDetailedVisible && (
                <div className={searchHolderFilters}>
                  <div className={style.detailedFilter}>
                    <SearchDetailed
                      values={values}
                      selectedFilters={selectedFilters}
                      setFieldValue={setFieldValue}
                      handleSubmitSearch={handleSubmitSearch}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </form>
      {isSearchDetailedVisible && (
        <div
          className={setClass({
            cover: true,
            isActive: isOpen,
          })}
        />
      )}
    </>
  );
};

export default memo(SearchForm, isEqual);
