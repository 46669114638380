import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { getRecentFilterIds } from '@lib/getRecentFilterIds';
import getSearchSuggestionUrl from '@lib/search/getSearchSuggestionUrl';

import { useAppDispatch } from '@hooks/redux/useAppDispatch';
import { useAppSelector } from '@hooks/redux/useAppSelector';

import {
  selectHoveredIndex,
  selectIsLoading,
  selectSuggestedSearch,
} from '@store/classifier/selector';
import { fetchSuggestedSearch } from '@store/classifier/thunk';
import { AllSuggestionType } from '@store/classifier/type';
import { selectSearchRecent } from '@store/search/selector';
import { fetchSearchRecent } from '@store/search/thunk';

export const useSearchSuggestion = ({ value, handleSubmitSearch }) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const recentSearch = useAppSelector(selectSearchRecent);
  const hoveredIndex = useAppSelector(selectHoveredIndex);
  const isLoading = useAppSelector(selectIsLoading);

  const [suggestions, setSuggestions] = useState<AllSuggestionType[] | null>(
    recentSearch
  );
  const [activeSuggestion, setActiveSuggestion] = useState(null);

  const [showSuggestion, setShowSuggestion] = useState(false);

  const suggestedSearch = useAppSelector(selectSuggestedSearch);

  const debouncedfetchSuggestedSearch = useMemo(() => {
    return debounce((event) => {
      return dispatch(fetchSuggestedSearch({ keywords: event.target.value }));
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSearchRecent = useCallback(() => {
    if (!isEmpty(recentSearch)) return;
    const filterIds = getRecentFilterIds();
    dispatch(fetchSearchRecent({ filterIds }));
  }, [dispatch, recentSearch]);

  useEffect(() => {
    if (!isEmpty(suggestedSearch) && value) {
      setSuggestions(suggestedSearch);
    } else {
      setSuggestions(recentSearch);
    }
  }, [recentSearch, suggestedSearch, suggestions, value]);

  useEffect(() => {
    setSuggestions(suggestedSearch);
  }, [suggestedSearch]);

  useEffect(() => {
    if (isEmpty(suggestedSearch) && isEmpty(recentSearch)) {
      setSuggestions(recentSearch);
    }
  }, [recentSearch, suggestedSearch]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowSuggestion(true);

    debouncedfetchSuggestedSearch(event);

    if (!event.target.value) {
      setSuggestions(recentSearch);
    }
  };

  const handleClick = (suggestion: AllSuggestionType) => {
    if (!suggestion) return;

    const url = getSearchSuggestionUrl(suggestion);
    router.push(url);

    setShowSuggestion(false);
    setActiveSuggestion(null);
  };

  const handleEnterKey = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const shouldHandleSuggestionClick =
      showSuggestion &&
      activeSuggestion !== null &&
      hoveredIndex !== activeSuggestion;

    if (shouldHandleSuggestionClick) {
      handleClick(suggestions[activeSuggestion]);
      setSuggestions(recentSearch);
    } else {
      handleSubmitSearch(e);
      setShowSuggestion(false);
    }
  };

  const handleUpArrowKey = () => {
    if (activeSuggestion === 0) {
      setActiveSuggestion(suggestions.length - 1);
    } else if (activeSuggestion) {
      setActiveSuggestion(activeSuggestion - 1);
    } else {
      setActiveSuggestion(suggestions.length - 1);
    }
  };

  const handleDownArrowKey = () => {
    if (activeSuggestion === suggestions.length - 1) {
      setActiveSuggestion(0);
    } else if (activeSuggestion !== null) {
      setActiveSuggestion(activeSuggestion + 1);
    } else {
      setActiveSuggestion(0);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!e.repeat && e.key === 'Enter') {
      handleEnterKey(e);
    }

    if (e.key === 'ArrowUp') {
      handleUpArrowKey();
    } else if (e.key === 'ArrowDown') {
      handleDownArrowKey();
    }
  };

  return {
    getSearchRecent,
    handleClick,
    onChange,
    onKeyDown,
    activeSuggestion,
    setActiveSuggestion,
    setSuggestions,
    suggestions,
    recentSearch,
    showSuggestion,
    setShowSuggestion,
    isLoading,
  };
};
