import { createSelector } from 'reselect';

import { formatCurrency } from 'src/lib/localization/formatCurrency';

import { PartnerItem } from '@type/partners/partners';

import { searchSelector } from '../rootSelector';

import { SearchInitialState } from './type';

export const selectIsLoading = createSelector(
  searchSelector,
  (slicedState: SearchInitialState) => {
    try {
      return slicedState.isLoading;
    } catch (e) {
      return true;
    }
  }
);

export const selectSearchedAds = createSelector(
  searchSelector,
  (slicedState: SearchInitialState) => {
    try {
      return slicedState.adsIds.map((id) => slicedState.byId[id]);
    } catch (e) {
      return [];
    }
  }
);

export const selectSearchedBreadcrumb = createSelector(
  searchSelector,
  (slicedState: SearchInitialState) =>
    slicedState.byId[Object.keys(slicedState.byId)[0]]
);

export const selectTotalAdsCount = createSelector(
  searchSelector,
  (slicedState: SearchInitialState) => {
    try {
      return formatCurrency(slicedState.total);
    } catch (e) {
      return 0;
    }
  }
);

export const selectFilterId = createSelector(
  searchSelector,
  (slicedState: SearchInitialState) => {
    try {
      return slicedState.filterId;
    } catch (e) {
      return 0;
    }
  }
);

export const selectTotalPages = createSelector(
  searchSelector,
  (slicedState: SearchInitialState) => {
    try {
      return slicedState.pages;
    } catch (e) {
      return 0;
    }
  }
);

export const selectHasReachedMax = createSelector(
  searchSelector,
  (slicedState: SearchInitialState) => {
    try {
      return slicedState.hasReachedMax;
    } catch (e) {
      return 0;
    }
  }
);

export const selectIsSearchExisting = createSelector(
  searchSelector,
  (slicedState: SearchInitialState) => {
    try {
      return slicedState.isSearchExisting;
    } catch (e) {
      return true;
    }
  }
);

export const selectIsSearchAllowed = createSelector(
  searchSelector,
  (slicedState: SearchInitialState) => {
    try {
      return slicedState.isSearchSaveAllowed;
    } catch (e) {
      return 0;
    }
  }
);

export const selectSearchRecent = createSelector(
  searchSelector,
  (slicedState: SearchInitialState) => slicedState.searchRecent
);

export const selectSeoCategoryText = createSelector(
  searchSelector,
  (slicedState: SearchInitialState) => {
    try {
      return slicedState.seoCategoryText;
    } catch (e) {
      return '';
    }
  }
);

export const selectSeoCategoryTextAllowed = createSelector(
  searchSelector,
  (slicedState: SearchInitialState) => {
    try {
      return slicedState.isSeoCategoryTextAllowed;
    } catch (e) {
      return 0;
    }
  }
);

export const selectIsError = createSelector(
  searchSelector,
  (slicedState: SearchInitialState) => {
    try {
      return slicedState.isError;
    } catch (e) {
      return true;
    }
  }
);

export const selectCategoryPartner = createSelector(
  searchSelector,
  (slicedState: SearchInitialState) => {
    try {
      return slicedState.categoryPartners[0];
    } catch (e) {
      return {} as PartnerItem;
    }
  }
);

export const selectCategoryPartnerExists = createSelector(
  searchSelector,
  (slicedState: SearchInitialState) => {
    try {
      return slicedState.categoryPartners.length > 0;
    } catch (e) {
      return false;
    }
  }
);

export const selectSalePartner = createSelector(
  searchSelector,
  (slicedState: SearchInitialState) => {
    try {
      return slicedState.salePartners[0];
    } catch (e) {
      return {} as PartnerItem;
    }
  }
);

export const selectIsSalePartnerExists = createSelector(
  searchSelector,
  (slicedState: SearchInitialState) => {
    try {
      return slicedState.salePartners.length > 0;
    } catch (e) {
      return false;
    }
  }
);

export const selectSearchFilterScreen = createSelector(
  searchSelector,
  (slicedState: SearchInitialState) => {
    try {
      return slicedState.searchFilterScreen;
    } catch (e) {
      return null;
    }
  }
);

export const selectMyKpSearchFilterScreen = createSelector(
  searchSelector,
  (slicedState: SearchInitialState) => {
    try {
      return slicedState.myKpSearchFilterScreen;
    } catch (e) {
      return null;
    }
  }
);

export const selectSearchTypeBrowsing = createSelector(
  searchSelector,
  (slicedState: SearchInitialState) => {
    try {
      return slicedState.searchFiltersOrigin === 'browsing';
    } catch (e) {
      return false;
    }
  }
);

export const selectShowSearchIsbnInfo = createSelector(
  searchSelector,
  (slicedState: SearchInitialState) => {
    try {
      return slicedState.showSearchIsbnInfo;
    } catch (e) {
      return false;
    }
  }
);

export const selectSearchFilterNavigationOrigin = createSelector(
  searchSelector,
  (slicedState: SearchInitialState) => {
    try {
      return slicedState.searchFilterNavigationOrigin;
    } catch (e) {
      return 'byFilters';
    }
  }
);
