import {
  AdRoute,
  CategorySearchRoute,
  GroupSearchRoute,
  SearchRoute,
} from '@server/routes';

import { AllSuggestionType, SuggestionParams } from '@store/classifier/type';

const generateQueryString = ({
  type = '',
  params = {} as SuggestionParams,
  ...rest
}: AllSuggestionType) => ({
  ...rest,
  ...params,
  params: '',
  username: '',
  category: '',
  firstParam: '',
  group: '',
  name: '',
  categoryName: '',
  groupName: '',
  filterId: '',
  ...(type === 'isbn' && { isbn: true }),
});

const getSearchSuggestionUrl = (suggestion: AllSuggestionType) => {
  const { groupId, categoryId, groupName, categoryName, adName, adId } =
    suggestion;

  const queryString = generateQueryString(suggestion);

  const routes = {
    ad: AdRoute.generateUrl({
      firstParam: categoryName,
      group: groupName,
      ad: adName,
      adId,
    }),
    group: GroupSearchRoute.generateUrl(
      {
        firstParam: categoryName,
        group: groupName,
        groupId,
      },
      { ...queryString }
    ),
    category: CategorySearchRoute.generateUrl(
      { firstParam: categoryName, categoryId },
      { ...queryString }
    ),
    default: SearchRoute.generateUrl({}, { ...queryString }),
  };

  let url = routes.default;

  if (adName) {
    url = routes.ad;
  } else if (categoryId && groupId) {
    url = routes.group;
  } else if (categoryId) {
    url = routes.category;
  }

  return url;
};

export default getSearchSuggestionUrl;
