import React from 'react';

import { IconProps } from '@type/icons';

import asIcon from '../asIcon';

const ArrowDownIconSmall = ({ className, stroke, fill }: IconProps) => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill={fill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      id="arrowDown"
    >
      <path
        d="M7 3L4.14133 5.93973C4.10387 5.97832 4.05302 6 4 6C3.94698 6 3.89613 5.97832 3.85867 5.93973L1 3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default asIcon(ArrowDownIconSmall);
