import { useCallback, useEffect } from 'react';
import isElement from 'lodash/isElement';

import isServer from '@lib/ssrSetup/isServer';

type RefType = React.MutableRefObject<HTMLElement | null> | any;

const useOutsideClick = (
  ref: RefType,
  onOutsideClick: () => void,
  root = '__next'
) => {
  let rootNode;

  if (!isServer && !rootNode) {
    rootNode = document.getElementById(root);
  }

  const onMouseUp = useCallback(
    (e) => {
      const isInside = Array.isArray(ref)
        ? ref.some(
            (element) =>
              !!element &&
              !!element.current &&
              isElement(element.current) &&
              element.current.contains(e.target)
          )
        : !!ref &&
          !!ref.current &&
          isElement(ref.current) &&
          ref.current.contains(e.target);

      if (!isInside) {
        onOutsideClick();
      }
    },
    [onOutsideClick, ref]
  );

  useEffect(() => {
    if (rootNode) {
      rootNode.addEventListener('mouseup', onMouseUp);
      return () => {
        rootNode.removeEventListener('mouseup', onMouseUp);
      };
    }
  }, [onMouseUp, rootNode]);
};

export default useOutsideClick;
